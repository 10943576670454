var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('win-block',{scopedSlots:_vm._u([{key:"win-title-left",fn:function(){return [_vm._v("Донаты игроков")]},proxy:true},{key:"win-title-right",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.balance_global)+" ")])]},proxy:true}])},[_c('v-card',{attrs:{"flat":"","min-height":"450px"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.datetime_source",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("time")(item.datetime_source)))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("date")(item.datetime_source)))])]}},{key:"item.paysystem_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"amount message111"},[_vm._v(_vm._s(item.amount))]),_c('div',{staticClass:"pay yandex"},[_vm._v(_vm._s(item.paysystem_name))])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [(item.user_name || item.amount<0)?_c('div',[_vm._v(" "+_vm._s(item.user_name))]):_c('v-btn',{staticClass:"softvision",attrs:{"x-small":"","color":"secondary","elevation":"0"},on:{"click":function($event){_vm.form_users.item_edit=item; _vm.form_users.show=true}}},[_vm._v(" нет данных ")])]}},{key:"item.message_user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"message"},[_c('div',[_vm._v(_vm._s(item.message_user))])])]}},{key:"item.comment_paysystem",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"box message text--secondary"},[_c('div',{staticClass:"comment"},[_vm._v(_vm._s(item.comment_admin))]),_c('div',[_vm._v(_vm._s(item.comment_paysystem))]),_c('v-btn',{staticClass:"flybtn",attrs:{"absolute":"","right":"","x-small":"","icon":""},on:{"click":function($event){return _vm.formOpen('comments',item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])})],1),_c('form-comment',{attrs:{"form":_vm.form_comment},on:{"saved":function($event){return _vm.formSaved($event)}}}),_c('form-users',{attrs:{"form":_vm.form_users},on:{"saved":function($event){return _vm.formSaved($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }