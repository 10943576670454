<template>
  <win-block>
    <template #win-title-left>Донаты игроков</template>

    <template #win-title-right>
      <div>
        {{ balance_global }}
      </div>
    </template>

    <v-card flat min-height="450px">

      <v-data-table
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
      >
        <template v-slot:item.datetime_source="{ item }">
          <div class="time">{{ item.datetime_source|time }}</div>
          <div class="date">{{ item.datetime_source|date }}</div>
        </template>

        <template v-slot:item.paysystem_name="{ item }">
          <div class="amount message111">{{ item.amount }}</div>
          <div class="pay yandex">{{ item.paysystem_name }}</div>
        </template>

        <template v-slot:item.user_name="{ item }">
          <div v-if="item.user_name || item.amount<0"> {{ item.user_name }}</div>
          <!--          <v-btn v-else x-small color="secondary" elevation="0" @click="formOpen('users',item)">-->
          <v-btn v-else x-small color="secondary" elevation="0" class="softvision"
                 @click="form_users.item_edit=item; form_users.show=true">
            нет данных
          </v-btn>
        </template>

        <template v-slot:item.message_user="{ item }">
          <div class="message">
            <div>{{ item.message_user }}</div>
          </div>
        </template>

        <template v-slot:item.comment_paysystem="{ item }">
          <div class="box message text--secondary">
            <div class="comment">{{ item.comment_admin }}</div>
            <div>{{ item.comment_paysystem }}</div>
            <v-btn absolute right x-small icon class="flybtn"
                   @click="formOpen('comments',item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>

      </v-data-table>

    </v-card>


    <form-comment :form="form_comment" @saved="formSaved($event)"/>

    <form-users :form="form_users" @saved="formSaved($event)"/>


  </win-block>
</template>

<script>
export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "form-comment": () => import('./table-operations-form-comment'),
    "form-users": () => import('./table-operations-form-users'),
  },
  data() {
    return {
      form_users: {show: false, item_edit: {}},
      form_comment: {show: false, item_edit: {}},
      loading: true,
      headers: [
        {text: 'Время', value: 'datetime_source'},
        {text: 'Юзер', value: 'user_name', align: 'center'},
        {text: 'Донат', value: 'paysystem_name',},
        {text: 'Сообщение', value: 'message_user', cellClass: 'message', sortable: false,},
        {text: 'Комментарий', value: 'comment_paysystem', sortable: false},
        // {text: 'Заметка', value: 'comment_admin', sortable: false},
      ],
      items: [],
      balance_global: 0,
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.finance_project_operations)
          .then(response => {
            this.items = response.data.operations
            this.balance_global = response.data.balance
            this.loading = false
          })
    },
    formOpen(name, item) {
      // window.console.log("=================")
      // window.console.log("form_users", this.form_users.show,)
      // window.console.log("form_comment", this.form_comment.show,)
      // if (name === 'users') {
      //   this.form_users.item_edit = item
      //   this.form_users.show = true
      // }
      if (name === 'comments') {
        // this.form_comment.item_edit = item
        this.form_comment.item_edit = JSON.parse(JSON.stringify(item))
        this.form_comment.show = true
      }
      // window.console.log("form_users", this.form_users.show,)
      // window.console.log("form_comment", this.form_comment.show,)
    },
    formSaved(item) {
      let index = this.items.findIndex(obj => obj.id === item.id);
      this.items = Object.assign([], this.items, {[index]: item})
    }
  }
}
</script>


<style scoped>

.message:hover .flybtn {
  /*display: block;*/
  opacity: 1;
}

.flybtn {
  top: 20%;
  opacity: 0;
}

.box {
  position: relative;
}

.message {
  font-size: smaller;
  height: 100%;
}

.comment {
  font-size: small;
}

.date {
  font-size: xx-small;
}

.amount, .user, .time {
  font-size: large;
}

.pay {
  font-size: xx-small;
  text-shadow: #969696 1px 1px 0, #969696 -1px -1px 0, #969696 -1px 1px 0, #969696 1px -1px 0;
  opacity: 0.5;
}

.yandex {
  color: hsla(50, 100%, 80%, 1);
}

.donatepay {
  color: hsla(100, 100%, 80%, 1);
}

</style>

/*
[
{
"user": {
"username": "ZEX",
"usid": "id10009",
"avatar": "/media/profile_images/avatar9.jpg"
},
"balance": 0
},
{
"user": {
"username": "superuser",
"usid": "id10001",
"avatar": null
},
"balance": 332
}
]
*/